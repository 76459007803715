import React from "react";
import Navbar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
export default function Disclaimer() {
  const PhoneNumber = {
    PhoneNo: "(888) 387-1856",
  };
  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <div
        data-elementor-type="wp-page"
        data-elementor-id="108"
        className="elementor elementor-108"
      >
        
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-7709e3a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="7709e3a"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-176d32d"
              data-id="176d32d"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-5c7be12 elementor-widget elementor-widget-text-editor nb"
                  data-id="5c7be12"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                   <h1 className="elementor-heading-title elementor-size-default">
                      DISCLAIMER
                    </h1>{" "}
                  <div className="elementor-widget-container">
                    <p>
                      The information provided by&nbsp;Your Family
                      Security&nbsp;(“we,” “us,” or “our”)
                      on&nbsp;yourfamiliysecurity.com (the&nbsp;“Site”)&nbsp;is
                      for general informational purposes only. All information
                      on&nbsp;the Site&nbsp;is provided in good faith, however
                      we make no representation or warranty of any kind, express
                      or implied, regarding the accuracy, adequacy, validity,
                      reliability, availability, or completeness of any
                      information on&nbsp;the Site. UNDER NO CIRCUMSTANCE SHALL
                      WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY
                      KIND INCURRED AS A RESULT OF THE USE OF&nbsp;THE
                      SITE&nbsp;OR RELIANCE ON ANY INFORMATION PROVIDED
                      ON&nbsp;THE SITE. YOUR USE OF&nbsp;THE SITE&nbsp;AND YOUR
                      RELIANCE ON ANY INFORMATION ON&nbsp;THE SITE&nbsp;IS
                      SOLELY AT YOUR OWN RISK.
                    </p>{" "}
                  </div>
                </div>
                <div className="nc">
                <div
                  className="elementor-element elementor-element-fa08e9e elementor-widget elementor-widget-heading"
                  data-id="fa08e9e"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h3 className="elementor-heading-title elementor-size-default">
                      PROFESSIONAL DISCLAIMER
                    </h3>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-307d11a elementor-widget elementor-widget-text-editor"
                  data-id="307d11a"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      The Site cannot and does not contain&nbsp;Final Expense /
                      Burial Insurance&nbsp;advice. The&nbsp;Final Expense /
                      Burial Insurance&nbsp;information is provided for general
                      informational and educational purposes only and is not a
                      substitute for professional advice. Accordingly, before
                      taking any actions based upon such information, we
                      encourage you to consult with the appropriate
                      professionals. We do not provide any kind of&nbsp;Final
                      Expense / Burial Insurance&nbsp;advice.&nbsp;THE USE OR
                      RELIANCE OF ANY INFORMATION CONTAINED ON&nbsp;THE
                      SITE&nbsp;IS SOLELY AT YOUR OWN RISK.
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-e0fe07f elementor-widget elementor-widget-heading"
                  data-id="e0fe07f"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h3 className="elementor-heading-title elementor-size-default">
                      TCPA DISCLAIMER
                    </h3>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-6210faf elementor-widget elementor-widget-text-editor"
                  data-id="6210faf"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      Notwithstanding any current or prior election to opt in or
                      opt out of receiving telemarketing calls, or SMS messages
                      (text messages) from our representatives, agents,
                      affiliates, you expressly consent to be contacted by YOUR
                      FAMILY SECURITY, our agents, representatives, affiliates,
                      or anyone calling on our behalf. This is for any purposes
                      relating to your request for Final Expense / Burial
                      Insurance service, at any telephone number, or physical or
                      electronic address you provide or at which you may be
                      reached. You agree we may contact you in any way,
                      including SMS messages (text messages), calls using
                      prerecorded messages or artificial voice, and calls and
                      messages delivered using auto telephone dialing system or
                      an automatic texting system.
                    </p>
                    <p>
                      Automated messages may be played when the telephone is
                      answered whether by you or someone else. If an agent or
                      representative of YOUR FAMILY SECURITY calls, he or she
                      may also leave a message on your answering machine, voice
                      mail, or send one via text. You certify that the telephone
                      numbers that you have provided, are in fact your contact
                      numbers, and you agree to receive calls at each phone
                      number you have provided YOUR FAMILY SECURITY. You agree
                      to promptly alert YOUR FAMILY SECURITY if you stop using a
                      particular phone number. Your cell/mobile telephone
                      provider will charge you according to the type of account
                      you carry with those companies. You may opt out of any
                      future contact via text message by replying anytime with
                      “STOP”. You agree that YOUR FAMILY SECURITY may contact
                      you by email, using any email address you have provided or
                      that you provide in the future. You may opt out of any
                      future contact via email message by replying anytime with
                      “UNSUBSCRIBE”. YOUR FAMILY SECURITY may listen to and/or
                      record calls between you and any representative without
                      notice, as permitted by applicable laws. For example, we
                      listen to calls to monitor for quality purposes.
                    </p>{" "}
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
