import React, { useEffect } from "react";
export default function ExternalScriptsLoader() {
  useEffect(() => {
    // Direct Traffic Script
    function loadRingba() {
      const script = document.createElement("script");
      script.id = "__script__id__";
      script.type = "text/javascript";
      script.async = true;
      script.src = "//b-js.ringba.com/CA80fe21f8f6e5475eba9462377526957c";
      document.body.append(script);
    }

    document.addEventListener("DOMContentLoaded", function () {
      window._rgba_tags = undefined;
      loadRingba();
      loadScript();
      checkCookie();
    });

    function loadScript() {
      console.log("Univerisal script loaded");
      const script = document.createElement("script");
      script.id = "__script__id__";
      script.type = "text/javascript";
      script.async = true;
      script.src =
        "https://track.imgtrx3.com/uniclick.js?attribution=lastpaid&cookiedomain=yourfamilysecurity.com&cookieduration=30&defaultcampaignid=631627ee64caf90001db29c3&regviewonce=false";

      document.body.append(script);
    }

    function checkCookie() {
      console.log("Checking Cookie");
      var myInterval = setInterval(() => {
        let myCookie = sessionStorage.getItem("rtkclickid");
        if (myCookie && window._rgba_tags) {
          console.log("COOKIE RECEIVED", myCookie);
          (window._rgba_tags = window._rgba_tags || []).push({
            clickID: myCookie,
          });
          clearInterval(myInterval);
        } else {
          console.log("Cookie is not there");
        }
      }, 2000);
    }
    // Load the scripts
    const ringbaScript = loadRingba();
    const universalScript = loadScript();

    // Start checking cookies
    checkCookie();

    // Cleanup function
    return () => {
      if (ringbaScript) document.body.removeChild(ringbaScript);
      if (universalScript) document.body.removeChild(universalScript);
    };
  }, []);
  return null;
}
