import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import heroSide from "../assets/images/photos/her-side.jpg";

export default function HeroSection({ PhoneNumber, heroContent }) {
  const elementsToAnimate = [
    useRef(), // Heading
    useRef(), // Paragraph
    useRef(), // Call Now button
    useRef(), // Hero Image
  ];

  useEffect(() => {
    elementsToAnimate.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);

  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;

  return (
    <div className="HeroSection">
      <section className="slider-three">
        <div className="slider-three__shape-1 float-bob-y"></div>
        <div className="slider-three__shape-2 float-bob-x"></div>
        <div className="slider-three__shape-3 float-bob-y"></div>
        <div className="slider-three__shape-4 float-bob-y"></div>
        <div className="containerHero">
          <div className="rowHero">
            <div className="col1Hero">
              <div className="my-auto wow fadeInUp" data-wow-duration="1500ms">
                <h3 >
                  
                </h3>
                <h3 ref={elementsToAnimate[0]} className="slider-three__title" dangerouslySetInnerHTML={{ __html: heroContent.Heading }}></h3>
                <p ref={elementsToAnimate[1]} className="slider-three__text" dangerouslySetInnerHTML={{ __html: heroContent.Para }}></p>

                <div className="btn-group">
                  <a ref={elementsToAnimate[2]} href={`tel:${telPhoneNumber}`}>
                    CALL NOW: {PhoneNumber.PhoneNo}
                  </a>
                </div>
              </div>
            </div>
            <div className="col2Hero  fadeInUp">
              <div ref={elementsToAnimate[3]} className="slider-three__image">
                <img src={heroContent.Image} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
