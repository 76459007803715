import React from "react";
import Navbar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";

export default function PrivacyPolicy() {
  const PhoneNumber = {
    PhoneNo: "(888) 387-1856",
  };
  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <div
        data-elementor-type="wp-page"
        data-elementor-id="116"
        className="elementor elementor-116"
      >
    
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-7709e3a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="7709e3a"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-176d32d"
              data-id="176d32d"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-5c7be12 elementor-widget elementor-widget-text-editor nb"
                  data-id="5c7be12"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                     <h1 className="elementor-heading-title elementor-size-default">
                      Privacy Policy
                    </h1>{" "}
                  <div className="elementor-widget-container">
                    <p>
                      Intermedia Marketing Group LLC operates the&nbsp;
                      <a href="http://firstratedentists.com/">
                        <strong>yourfamiliysecurity.com</strong>
                      </a>
                      &nbsp;website, which provides the SERVICE.
                    </p>
                    <p>
                      This page informs website visitors regarding our policies
                      regarding the collection, use, and disclosure of Personal
                      Information if anyone decides to use our Service, on the
                      Your Family Security website.
                    </p>
                    <p>
                      If you choose to use our Service, then you agree to the
                      collection and use of information in relation to this
                      policy. The Personal Information that we collect is used
                      for providing and improving the Service. We will not use
                      or share your information except as described in this
                      Privacy Policy.
                    </p>
                    <p>
                      The terms used in this Privacy Policy have the same
                      meanings as our Terms and Conditions, which are accessible
                      at&nbsp;
                      <a href="http://firstratedentists.com/">
                        <strong>yourfamiliysecurity.com</strong>
                      </a>
                      &nbsp;unless otherwise defined in this Privacy Policy.
                    </p>{" "}
                  </div>
                </div>
                <div className="nc">
                <div
                  className="elementor-element elementor-element-dfc0b37 elementor-widget elementor-widget-heading"
                  data-id="dfc0b37"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h2 className="elementor-heading-title elementor-size-default">
                      Definitions
                    </h2>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-2313cfa elementor-widget elementor-widget-heading"
                  data-id="2313cfa"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      Service
                    </h4>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-307d11a elementor-widget elementor-widget-text-editor"
                  data-id="307d11a"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      Service is the&nbsp;
                      <a href="http://firstratedentists.com/">
                        Yourfamiliysecurity.com
                      </a>
                      &nbsp;operated by Intermedia Marketing Group LLC.
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-1633d8f elementor-widget elementor-widget-heading"
                  data-id="1633d8f"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      Personal Data
                    </h4>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-4c1a289 elementor-widget elementor-widget-text-editor"
                  data-id="4c1a289"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      Personal Data pertains to information about a living
                      person(s) who can be identified from this data or other
                      information in our possession or likely to come into our
                      possession.
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-c761324 elementor-widget elementor-widget-heading"
                  data-id="c761324"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      Usage Data
                    </h4>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-ca6ebcf elementor-widget elementor-widget-text-editor"
                  data-id="ca6ebcf"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      Usage Data has been collected automatically and is
                      generated using the Service, such as the duration of a
                      visit to the site.
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-cefc366 elementor-widget elementor-widget-heading"
                  data-id="cefc366"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      Cookies
                    </h4>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-c7beca2 elementor-widget elementor-widget-text-editor"
                  data-id="c7beca2"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      Cookies are files with a small amount of data commonly
                      used as a unique anonymous identifier. These are sent to
                      your browser from the website you visit and stored on your
                      computer’s hard drive.
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-093e330 elementor-widget elementor-widget-heading"
                  data-id="093e330"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      Data Controller
                    </h4>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-90ba6fd elementor-widget elementor-widget-text-editor"
                  data-id="90ba6fd"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      Data Controller pertains to the legal entity though
                      controls your information while participating in the
                      Service. We are a Data Controller of your personal
                      information. We determine the purposes for which it will
                      be processed and how it’s done in an effective manner that
                      ensures compliance with all relevant laws while still
                      maintaining the trust of our users.
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-55be108 elementor-widget elementor-widget-heading"
                  data-id="55be108"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      Data Processors (or Service Providers)
                    </h4>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-9ca3291 elementor-widget elementor-widget-text-editor"
                  data-id="9ca3291"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      The Data Processor (or Service Provider) is any natural or
                      legal person who processes data on behalf of the Data
                      Controller. We may use various service providers to
                      process your information more effectively.
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-8a2460f elementor-widget elementor-widget-heading"
                  data-id="8a2460f"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      Data Subject (or User)
                    </h4>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-b0244ae elementor-widget elementor-widget-text-editor"
                  data-id="b0244ae"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      Data Subjects are any individuals who use our Service and
                      whose Personal Data we collect.
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-edbf2dd elementor-widget elementor-widget-heading"
                  data-id="edbf2dd"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h2 className="elementor-heading-title elementor-size-default">
                      Disclosure of Data
                    </h2>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-efea299 elementor-widget elementor-widget-heading"
                  data-id="efea299"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      Disclosure for Law Enforcement
                    </h4>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-fd219a9 elementor-widget elementor-widget-text-editor"
                  data-id="fd219a9"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      Intermedia Marketing Group LLC has a responsibility to
                      protect your Personal Data. We will never release any of
                      it without complying with all applicable laws and
                      responding properly if asked by law enforcement or other
                      authorities.
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-aa25838 elementor-widget elementor-widget-heading"
                  data-id="aa25838"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      Legal Requirements
                    </h4>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-1f97170 elementor-widget elementor-widget-text-editor"
                  data-id="1f97170"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      Intermedia Marketing Group LLC may disclose your Personal
                      Data in the good faith belief that such action is
                      necessary to:
                    </p>
                    <ul>
                      <li>
                        To notify users about changes in service offerings or
                        adjustments based on user feedback
                      </li>
                      <li>Comply with a legal obligation</li>
                      <li>
                        Protect and defend the rights or property of Intermedia
                        Marketing Group LLC
                      </li>
                      <li>
                        Protect clients &amp; employees from any harm caused by
                        their actions where it cannot be avoided through
                        reasonable precautions taken beforehand
                      </li>
                      <li>
                        Prevent or investigate potential wrongdoing in
                        connection with the Service
                      </li>
                      <li>
                        Protect the personal safety of users of the Service
                      </li>
                      <li>Protect against personal liability</li>
                    </ul>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-9de752d elementor-widget elementor-widget-heading"
                  data-id="9de752d"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      Security of Data
                    </h4>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-42de23e elementor-widget elementor-widget-text-editor"
                  data-id="42de23e"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      The security of your data is a top priority for us. We’ll
                      do everything we can to protect it but remember that no
                      electronic storage media method will ever be 100% secure
                      when sending information over the internet.
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-3b08488 elementor-widget elementor-widget-heading"
                  data-id="3b08488"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      Our Policy on “Do Not Track” Signals under the California
                      Online Protection Act
                    </h4>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-e5661d1 elementor-widget elementor-widget-text-editor"
                  data-id="e5661d1"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      We do not support Do Not Track (DNT). You can set this
                      preference in your web browser to inform websites that
                      they should not track your activity.
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-88e0eac elementor-widget elementor-widget-heading"
                  data-id="88e0eac"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      Your Data Protection Rights Under GDPR
                    </h4>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-49f141d elementor-widget elementor-widget-text-editor"
                  data-id="49f141d"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      If you are a resident of the European Economic Area (EEA),
                      then we aim to make certain that your Personal Data is
                      processed fairly and in accordance with data protection
                      laws.
                      <br />
                      Should you request information about the Personal Data we
                      hold about you or want it removed, please contact us so we
                      may act accordingly.
                    </p>
                    <p>
                      You may also have the following data protection rights:
                    </p>
                    <ul>
                      <li>
                        <strong>The right to be informed —&nbsp;</strong>You
                        have the right to be told how your Personal Data will be
                        processed by the Data Controller. This is normally done
                        through a privacy notice.
                      </li>
                      <li>
                        <strong>
                          The right to access your Personal Data —
                        </strong>
                        You have the right to ask the Data Controller whether
                        they are processing your Personal Data, and if they are,
                        you can request a copy of that personal information.
                      </li>
                      <li>
                        <strong>The right of rectification —</strong>&nbsp;You
                        have the right to ensure your information is rectified
                        if it is found to be incomplete or inaccurate.
                      </li>
                      <li>
                        <strong>
                          The right to have your Personal Data erased —
                        </strong>
                        Also known as the “right to be forgotten” and, in
                        certain circumstances, you can ask the Data Controller
                        to erase your data.
                      </li>
                      <li>
                        <strong>The right to withdraw consent —&nbsp;</strong>
                        You have the right to withdraw your consent at any point
                        in time when Intermedia Marketing Group LLC relies on
                        your consent to process personal information.
                      </li>
                      <li>
                        <strong>
                          The right to restrict processing of your Personal Data
                          —
                        </strong>
                        You have the right to ask the Data Controller to
                        restrict or stop processing your Personal Data under
                        certain circumstances.
                      </li>
                      <li>
                        <strong>The right to data portability —</strong>You have
                        the right, in certain circumstances, to ask the Data
                        Controller for a copy of your data to re-use for your
                        own purposes.
                      </li>
                      <li>
                        <strong>The right to object —</strong>You have the
                        right, in certain circumstances, to object to the Data
                        Controller processing your data.
                      </li>
                      <li>
                        <strong>
                          Rights in relation to automated decision making and
                          profiling —
                        </strong>
                        You have the right, in certain circumstances, to ask the
                        Data Controller not to make decisions about you that are
                        based solely on automated processing or profiling
                      </li>
                    </ul>
                    <p>
                      Please note that to remain compliant with law enforcement,
                      we may ask that you verify your identity before responding
                      to or taking action on your request.
                    </p>
                    <p>
                      You also have the right to file a complaint with a Data
                      Protection Authority about our collection and use of your
                      information. If you have questions or want more
                      information, please contact your local European Economic
                      Area (EEA) data protection authority.
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-c32cabd elementor-widget elementor-widget-heading"
                  data-id="c32cabd"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      Service Providers
                    </h4>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-2165a09 elementor-widget elementor-widget-text-editor"
                  data-id="2165a09"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      We may employ third-party companies (Service Providers) to
                      help us deliver our Service, such as by handling customer
                      support or sending email newsletters about new services.
                      These Service Providers have access to Personal Data but
                      are prohibited from disclosing any information you shared
                      with them unless necessary.
                    </p>
                    <p>
                      Service Providers are only to perform tasks on our behalf
                      and may not use or disclose your Personal Data for any
                      other purpose.
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-9221be3 elementor-widget elementor-widget-heading"
                  data-id="9221be3"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h2 className="elementor-heading-title elementor-size-default">
                      Analytics
                    </h2>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-8cc179a elementor-widget elementor-widget-heading"
                  data-id="8cc179a"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      Google Analytics
                    </h4>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-42fa3c2 elementor-widget elementor-widget-text-editor"
                  data-id="42fa3c2"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      Google uses the data collected from its Analytics service
                      to tailor a more personalized user experience. The
                      information gathered by Google Analytics doesn’t contain
                      any personally identifiable data, like your name or email
                      address. It simply records how many visitors there were on
                      a specific day and time frame.
                    </p>
                    <p>
                      You can choose to opt out of making your activity on the
                      Service available to Google Analytics by installing the
                      appropriate browser add-on. The Google Analytics opt-out
                      browser add-on prevents sharing information with Google
                      Analytics about your activity.
                    </p>
                    <p>
                      For additional information on Google’s privacy practices,
                      visit the Google Privacy Terms web page
                      <a href="https://policies.google.com/privacy?hl=en">
                        &nbsp;found here.
                      </a>
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-c387eae elementor-widget elementor-widget-heading"
                  data-id="c387eae"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      Behavioral Remarketing
                    </h4>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-0d97a1a elementor-widget elementor-widget-text-editor"
                  data-id="0d97a1a"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      Intermedia Marketing Group LLC uses remarketing services
                      to advertise on other third-party websites you visit after
                      visiting the Service. Cookies are used to inform,
                      optimize, and serve advertisements based on prior visits
                      to the Service.
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-d6d3597 elementor-widget elementor-widget-heading"
                  data-id="d6d3597"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      Google AdWords
                    </h4>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-7b3ee1c elementor-widget elementor-widget-text-editor"
                  data-id="7b3ee1c"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      Google AdWords is a service provided by Google Inc., which
                      allows Intermedia Marketing Group LLC to advertise the
                      Service through online advertisements. You can opt-out
                      <a href="https://adssettings.google.com/authenticated">
                        &nbsp;of this advertising program&nbsp;
                      </a>
                      to not have data collected and used for personalized
                      content based on visitors’ browsing behavior when using
                      web browsers such as Chrome and Firefox.
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-3555980 elementor-widget elementor-widget-heading"
                  data-id="3555980"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      Bing Ads Remarketing
                    </h4>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-5a5bb38 elementor-widget elementor-widget-text-editor"
                  data-id="5a5bb38"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      Bing Ads Remarketing is provided by Microsoft Inc. You can
                      also choose to opt-out of Bing Ads by following their
                      <a href="https://about.ads.microsoft.com/en-us/resources/policies/personalized-ads">
                        &nbsp;instructions located here.
                      </a>
                    </p>
                    <p>
                      Learn about Microsoft privacy policies by visiting
                      their&nbsp;
                      <a href="https://privacy.microsoft.com/en-us/PrivacyStatement">
                        Privacy Policy page.
                      </a>
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-faf2c20 elementor-widget elementor-widget-heading"
                  data-id="faf2c20"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      Links to Other Sites
                    </h4>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-4e99f22 elementor-widget elementor-widget-text-editor"
                  data-id="4e99f22"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      Our Service may contain links to other sites. If you click
                      on a third-party link, you will be directed to that site.
                      Note that these external sites are not operated by us.
                      Therefore, we strongly advise you to review the Privacy
                      Policy of these websites. We have no control over and
                      assume no responsibility for any third-party sites or
                      services’ content, privacy policies, or practices.
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-3572e36 elementor-widget elementor-widget-heading"
                  data-id="3572e36"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      Children's Privacy
                    </h4>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-7165ab6 elementor-widget elementor-widget-text-editor"
                  data-id="7165ab6"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      Our Services do not address anyone under the age of 13. We
                      do not knowingly collect personally identifiable
                      information from children under 13. If we discover that a
                      child under 13 has provided us with personal information,
                      we immediately delete this from our servers. If you are a
                      parent or guardian and you are aware that your child has
                      provided us with personal information, please contact us
                      so that we will be able to take the necessary actions.
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-e0e42f8 elementor-widget elementor-widget-heading"
                  data-id="e0e42f8"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-title elementor-size-default">
                      Changes to This Privacy Policy
                    </h4>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-e905c62 elementor-widget elementor-widget-text-editor"
                  data-id="e905c62"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p>
                      We may update our Privacy Policy from time to time. Thus,
                      we advise you to review this page periodically for any
                      changes. We will notify you of any changes by posting the
                      new Privacy Policy on this page. These changes are
                      effective immediately after they are posted on this page.
                    </p>{" "}
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
