import React, { useState, useEffect } from "react";
import logo from "../assets/images/logo-300x70.webp";

export default function Navbar({ PhoneNumber }) {
  const [scrolled, setScrolled] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    // Show the navbar with an animation after component mounts
    setTimeout(() => setShowNavbar(true), 100);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;

  return (
    <div className={`Navbar ${scrolled ? 'scrolled' : ''} ${showNavbar ? 'show' : ''}`}>
      <div className="Navbar-c">
        <div className="logo">
          <a href="/"><img src={logo} alt="" /></a>
        </div>
        <div className="btn-group">
          <a href={`tel:${telPhoneNumber}`}>CALL NOW: {PhoneNumber.PhoneNo}</a>
        </div>
      </div>
    </div>
  );
}
