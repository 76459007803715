import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
export default function WhyChooseUs({ PhoneNumber}) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach(ref => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };
  
  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
   <div className="WhyChooseUs">
    
 
  
  
   </div>
  );
}
