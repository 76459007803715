import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
export default function Services({ PhoneNumber, sectionData , cardData  }) {
  // const cardRefs = cardData.map(() => useRef());

  // useEffect(() => {
  //   cardRefs.forEach((ref) => {
  //     if (ref.current) {
  //       scrollAnimation.observeElement(ref.current);
  //     }
  //   });
  // }, [cardRefs]);

  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;

  return (
    <div className="Services">
      <div className="service-six">
        <div className="container">
          <div className="sec-title text-center">
            <p className="sec-title__tagline">{sectionData.tagline}</p>
            <h3 className="sec-title__title">{sectionData.title}</h3>
          </div>
          <div className="rowService gutter-y-30">
            {cardData.map((card, index) => (
              <div className="col-md-6 col-lg-3" key={index}>
                <div className="service-card-four" >
                  <div className="service-card-four__bg"></div>
                  <div className="service-card-four__content">
                    <div className="service-card-four__circle"></div>
                    <i className="service-card-four__icon flaticon-icon">
                      <img src={card.icon} alt="" />
                    </i>
                    <h3 className="service-card-four__title">
                      <a href="">{card.title}</a>
                    </h3>
                    <p className="service-card-four__text">{card.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="btn-group">
            <a href={`tel:${telPhoneNumber}`}>CALL NOW: {PhoneNumber.PhoneNo}</a>
          </div>
        </div>
      </div>
    </div>
  );
}
