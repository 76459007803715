import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import heroSide from "../assets/images/RI/9.webp";
export default function HowItWorks({ PhoneNumber }) {
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div className="HowWorks">
      <section class="about-three">
        <div class="about-three__bg"></div>
        <div class="container">
          <div class="rowHowWorks gutter-y-60">
            <div class="col-md-12 col-lg-6">
              <div class="about-three__content">
                <div class="sec-title text-start">
                  
                  <h3 class="sec-title__title">Why Choose Us?</h3>
                </div>
                <div className="p-c">
                  <p>We connect you with final expense and burial insurance options that cover funeral costs and remaining medical or legal expenses. Our partners offer affordable, easy-to-apply policies, ensuring your loved ones are financially protected in the event of your death.</p>
                </div>

                <ul class="list-unstyled about-three__list">
                  <li>
                    <div class="about-three__list__icon">
                      <i class="flaticon-healthcare"></i>
                    </div>
                    <div class="about-three__list__content">
                     
                      <p class="about-three__list__text">
                        Say goodbye to your loved ones with dignity and without
                        financial stress
                      </p>
                      <p class="about-three__list__text">
                        Let them remember you with happy memories, not sadness
                        and debt
                      </p>
                      <p class="about-three__list__text">
                        Let your loved ones focus on healing, not on bills
                      </p>
                      <p class="about-three__list__text">
                        Rest easy knowing you won't leave them with a financial
                        burden
                      </p>
                    </div>
                  </li>
                </ul>
                <div className="btn-group">
                  <a href={`tel:${telPhoneNumber}`}>
                    {" "}
                    CALL NOW:{PhoneNumber.PhoneNo}
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">
              <div
                class="about-three__image wow fadeInUp"
                data-wow-duration="1500ms"
              >
                <img src={heroSide} class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
