import React, { useEffect, useRef } from "react";
import scrollAnimation from "../../utils/scrollAnimation";
import heroSide from "../assets/images/RI/3.webp";
export default function Contact({ PhoneNumber , ContactContnt }) {
  const backgroundImageUrl = `url(${ContactContnt.Image})`;
  const cardRefs = [useRef(), useRef(), useRef()];

  useEffect(() => {
    cardRefs.forEach((ref) => {
      if (ref.current) {
        scrollAnimation.observeElement(ref.current);
      }
    });
  }, []);
  const formatPhoneNumberForTel = (number) => {
    return number.replace(/\D/g, "");
  };

  const telPhoneNumber = `+1${formatPhoneNumberForTel(PhoneNumber.PhoneNo)}`;
  return (
    <div className="Contact">
      <div className="ContactFlex">
        <div
          className="contactBgImage"
          style={{
            backgroundImage: backgroundImageUrl,
           
          }}
        ></div>
        <div className="ContactContent">
          <h2>
          {ContactContnt.Heading}
          </h2>
          <div className="btn-group">
            <a href={`tel:${telPhoneNumber}`}>
              {" "}
              CALL NOW:{PhoneNumber.PhoneNo}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
