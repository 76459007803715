import React from "react";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import WhyChooseUs from "./components/WhyChooseUs";
import Services from "./components/Services";
import HowItWorks from "./components/HowItWorks";
import Contact from "./components/Contact";
import MobileNavbar from "./components/MobileNavbar";
import Footer from "./components/Footer";
import heroSide from "./assets/images/RI/5.webp";
import icon1 from "./assets/images/icons/How Do I Get Final Expense Insurance.png";
import icon2 from "./assets/images/icons/How Much Does Final Expense Insurance Cost.png";
import icon3 from "./assets/images/icons/What is Final Expense Insurance.png";
import icon4 from "./assets/images/icons/Why Do I Need Final Expense Insurance.png";
import cImage from   "./assets/images/RI/3.webp";
export default function landingPage() {
  const heroContent = {
    Image: heroSide,
    Heading: `Secure Your Legacy </br>  With Reliable </br> Final Expense Insurance`,
    Para: `Connecting You with Premier </br> Family Protection`,
  };
  const ContactContnt = {
    Heading: `    Providing Financial Security 
          For Your Family`,
          Image: cImage,
  };
  const sectionData = {
    tagline: "What We Offer",
    title: "Final Expense Insurance: Your Guide",
  };

  const cardData = [
    {
      icon: icon3,
      title: "What is Final Expense Insurance?",
      text: "Final expense insurance, also known as burial insurance, covers end-of-life costs such as funeral arrangements and any outstanding medical or legal expenses. This type of policy is typically more affordable than traditional life insurance since it covers a specific amount of expenses.",
    },
    {
      icon: icon4,
      title: "Why Do I Need Final Expense Insurance?",
      text: "Planning for the unexpected is crucial. Without a plan for your final expenses, your loved ones may face the financial burden of covering these costs. A final expense policy can relieve your family from financial pressure during a challenging time.",
    },
    {
      icon: icon2,
      title: "How Much Does Final Expense Insurance Cost?",
      text: "Final expense insurance costs vary based on factors like age, health, and coverage amount. Generally, these policies are more affordable than traditional life insurance options.",
    },
    {
      icon: icon1,
      title: "How Do I Get Final Expense Insurance?",
      text: "To find the best policy for your needs and budget, contact one of our expert partners. They will help you determine the right coverage and guide you through the process.",
    },
  ];
  const PhoneNumber = {
    PhoneNo: "(888) 387-1856",
  };

  return (
    <div>
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection heroContent={heroContent} PhoneNumber={PhoneNumber} />
      <WhyChooseUs PhoneNumber={PhoneNumber} />
      <Services
        cardData={cardData}
        sectionData={sectionData}
        PhoneNumber={PhoneNumber}
      />
      <HowItWorks PhoneNumber={PhoneNumber} />

      <Contact ContactContnt={ContactContnt} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
