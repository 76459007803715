import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import MobileNavbar from "../components/MobileNavbar";
import Footer from "../components/Footer";
import useLoadScript from "../Utility/useLoadScript";
import ExternalScriptsLoader from "../Scripts/Home_fb_lps";
import HowItWorks from "../components/HowItWorks";
import Contact from "../components/Contact";
import WhyChooseUs from "../components/WhyChooseUs";
import Services from "../components/Services";
import heroSide from "../assets/images/RI/11.webp";
import cImage from   "../assets/images/RI/4.webp";
import icon4 from "../assets/images/lp pages icons/fast coverage.png";
import icon3 from "../assets/images/lp pages icons/flexible payment.png";
import icon1 from "../assets/images/lp pages icons/lower premium.png";
import icon2 from "../assets/images/lp pages icons/medical exam.png";
export default function Bng_lp1() {
  useLoadScript("../Scripts/Home_fb_lps.js");
  const heroContent = {
    Image: heroSide,
    Heading: `New Program for </br> Seniors 
Avoid </br>This $20,000 Bill`,
    Para: `Affordable Burial Insurance 
With </br>This New Program`,
  };
  const ContactContnt = {
    Heading: `   Save Your Family from 
Burial Expenses`,
Image: cImage,
  };
  const sectionData = {
    tagline: "What We Offer",
    title: "Why is Final Expense Insurance Ideal for You?",
  };

  const cardData = [
    {
      icon: icon1,
      title: "LOWER PREMIUMS",
      text: "Our final expense insurance policies have some of the lowest premiums in the industry. This means that you can get the coverage you need without breaking the bank.",
    },
    {
      icon: icon2,
      title: "NO MEDICAL EXAM REQUIRED",
      text: "You don’t need to take a medical exam to qualify for our policies. We understand that not everyone is in perfect health, and we want to make it easy for you to get the coverage you need.",
    },
    {
      icon: icon3,
      title: "FLEXIBLE PAYMENTS",
      text: "We offer a variety of payment options to fit your budget. Choose from monthly, quarterly, or yearly payments.",
    },
    {
      icon: icon4,
      title: "FAST COVERAGE",
      text: "In most cases, our policies are effective within 24 hours, so you can get the peace of mind you need without having to wait.",
    },
  ];
  const PhoneNumber = {
    PhoneNo: "(888) 404-0528",
  };
  return (
    <div>
      <ExternalScriptsLoader />
      <Navbar PhoneNumber={PhoneNumber} />
      <MobileNavbar PhoneNumber={PhoneNumber} />
      <HeroSection heroContent={heroContent} PhoneNumber={PhoneNumber} />
      <WhyChooseUs PhoneNumber={PhoneNumber} />
      <Services
        cardData={cardData}
        sectionData={sectionData}
        PhoneNumber={PhoneNumber}
      />
      <HowItWorks PhoneNumber={PhoneNumber} />

      <Contact ContactContnt={ContactContnt} PhoneNumber={PhoneNumber} />
      <Footer PhoneNumber={PhoneNumber} />
    </div>
  );
}
